@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

:root {
  --primary: #107de2;
  --light: #eff5ff;
  --dark: #1b2c51;
}

body {
  font-family: "Plus Jakarta Sans";
  background-color: transparent;
}

span {
  font-weight: 500 !important;
}

.disable-hover:hover {
  background-color: #107de2;
  color: #fff;
  border-radius: 0;
  border-color: #107de2;
}

.switch-camera-view__SVGWrapper-sc-13l3hbu-0.czJHRh {
  display: none;
}

.bXOIRk {
  font-weight: 400;
  font-size: 17px !important;
}

.cYODjG {
  font-size: 17px !important;
}

/* .carousel-item {
  position: relative;
} */

.MuiCarousel-dots {
  position: absolute;
  bottom: 20px;
  /* Adjust this value to your desired position */
  width: 100%;
  display: flex;
  justify-content: center;
}

.swiper.swiper-initialized.swiper-horizontal.mySwiper.swiper-backface-hidden {
  width: 100%;
}

#svg-overlay {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0px;
  border: 40px solid rgb(255, 255, 255);
}

.userImage {
  width: 15vw;
}

#video {
  border-radius: 15px;
}

video {
  border-radius: 15px;
}

.navbarBox {
  display: none;
}

@media (max-width: 1000px) {
  .QrCodeImage {
    /* width: 145px;
    height: 145px; */
  }

  .userImage {
    width: 100%;
    height: 80vw;
  }

  .eventListImg {
    width: 100%;
    height: 285px;
    border-radius: 12.5px;
    background-color: #ffffff00;
    background-image: linear-gradient(180deg, #ffffff00 4%, #ffffff 100%);
  }

  .eventListOuterBox {
    display: flex;
    justify-content: center;
  }

  .eventBox {
    border-radius: 12.5px;

    height: 285px;
    margin: 15px;
  }

  .videoBox {
    width: 100%;
    /* left: 25%; */
    height: 90vw;
  }

  .videoBox2 {
    width: 100%;
  }

  #svg-overlay {
    border: 100px solid rgb(255, 255, 255);
  }

  .companyBanner {
    display: none;
  }

  .verificationOuterBox {
    display: flex;
    flex-direction: column;
    /* padding: 25px; */
  }

  .eventImg {
    height: 35vh;
  }

  /* .proceedNextBtn {
    width: 35%;
    padding: 12px 16px;
  }
*/

  .captureAgainBtn {
    color: black;
    /* width: 35%;
    
    padding: 12px 16px; */
  }

  .subheaderSectionBox {
    flex-direction: column;
    margin-bottom: 10px;
  }
}

@media (min-width: 1000px) {
  .QrCodeImage {
    /* width: 160px;
    height: 160px; */
  }

  .eventImageBox {
    display: none;
  }

  .eventListImg {
    width: 100%;
    height: 425px;
    border-radius: 12.5px;
    background-color: #ffffff00;
    background-image: linear-gradient(180deg, #ffffff00 4%, #ffffff 100%);
  }

  .eventListOuterBox {
    display: flex;
    justify-content: space-around;
  }

  .eventBox {
    border-radius: 12.5px;
    height: 425px;
    margin: 5px;
  }

  .eventImg {
    border-radius: 12.5px;
  }

  .companyBanner {
    height: 70vh;
    padding: 40px;
  }

  .navbarBox {
    display: flex;
  }

  .verificationOuterBox {
    display: flex;
    flex-direction: row;
    padding: 25px;
  }

  .proceedNextBtn {
    width: 35%;
    padding: 12px 16px;
  }

  .subheaderSectionBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .captureAgainBtn {
    background-color: rgb(245, 240, 229);
    width: 35%;
    color: black;
    padding: 12px 16px;
  }

  .footerBox {
    display: none;
  }
}

.logoImage {
  width: 12.5%;
}

@media (max-width: 900px) {
  .logoImage {
    width: 35%;
  }
  #svg-overlay {
    border: 75px solid rgb(255, 255, 255);
  }
}

@media (max-width: 700px) {
  .logoImage {
    width: 45%;
  }
  #svg-overlay {
    border: 30px solid rgb(255, 255, 255);
  }
}

::placeholder {
  color: rgb(161, 130, 74);
  opacity: 1; /* Firefox */
}

td {
  font-family: "Plus Jakarta Sans" !important;
  color: #0000006f;
}

.id-card-tag {
  width: 0;
  height: 0;
  border-left: 100px solid transparent;
  border-right: 100px solid transparent;
  border-top: 100px solid #d9300f;
  margin: -10px auto -30px auto;
}
.id-card-tag:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-top: 100px solid white;
  margin: -10px auto -30px auto;
  position: relative;
  top: -130px;
  left: -50px;
}

.id-card-hook {
  background-color: black;
  width: 70px;
  margin: 0 auto;
  height: 15px;
  border-radius: 5px 5px 0 0;
}
.id-card-hook:after {
  content: "";
  background-color: white;
  width: 47px;
  height: 6px;
  display: block;
  margin: 0px auto;
  position: relative;
  top: 6px;
  border-radius: 4px;
}

/* Boarding pass ,Travel history, feedback */

.boarding-pass small {
  font-family: "Plus Jakarta Sans !important";
  display: block;
  font-size: 10px;
  color: #a2a9b3;
  margin-bottom: 2px;
}
.boarding-pass strong {
  font-size: 15px;
  display: block;
}
.boarding-pass .cities::after {
  content: "";
  display: table;
  clear: both;
}
.boarding-pass .cities::after {
  position: relative;
  content: "";
  width: 160px;
  background: darkgrey;
  /* color: black; */
  height: 2px;
  top: -36px;
  border-radius: 10px;
  left: 65px;
}
.plane2 {
  position: absolute;
  top: 14px;
  left: 114px;
  z-index: 2;
  background: white;
  padding-left: 8px;
  padding-right: 8px;
}
.plane2 > img {
  width: 60px;
}

/* travel history */

.flight-card {
  font-family: "Plus Jakarta Sans";
  font-size: 13px;
  width: 96%;
  margin: 0px 0px 10px 6px;
  border-radius: 4px;
  height: 205px;
  overflow: hidden;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.2);
}
.flight-card .flight-card-header {
  height: 60px;
  width: 100%;
  background: linear-gradient(to bottom, #264b76 0%, #002c5f 100%);
  padding: 0px 20px;
  border-bottom: 7px solid #6dbe47;
  position: relative;
}
.flight-card .flight-card-header .flight-logo {
  /* height: 110px;
  width: 100%; */
}
.flight-card .flight-card-header .flight-logo img {
  width: 150px;
}
.flight-card .flight-card-header .flight-data {
  height: auto;
  border-top: 2px solid #3e5177;
  padding-top: 1em;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  text-align: left;
}
.flight-card .flight-card-header .flight-data span {
  display: block;
}
.flight-card .flight-card-header .flight-data .title {
  color: #838ea8;
  font-size: 11px;
}
.flight-card .flight-card-header .flight-data .detail {
  font-size: 13px;
  padding-top: 3px;
  color: white;
}
.flight-card .flight-card-content {
  width: 100%;
  height: auto;
  display: inline-block;
}
.flight-card .flight-card-content .flight-row {
  width: 100%;
  padding: 10px 15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.flight-card .flight-card-content .flight-row .plane {
  text-align: center;
  position: relative;
}
.flight-card .flight-card-content .flight-row .plane img {
  width: 45px;
}
.flight-card .flight-card-content .flight-row .plane:before {
  content: "";
  width: 45px;
  height: 3px;
  background: #adadad;
  position: absolute;
  top: 20px;
  left: -30px;
}
.flight-card .flight-card-content .flight-row .plane:after {
  content: "";
  width: 28px;
  height: 3px;
  background-color: #adadad;
  position: absolute;
  top: 20px;
  right: -13px;
}
.flight-card .flight-card-content .flight-from {
  text-align: left;
  float: left;
}
.flight-card .flight-card-content .flight-to {
  text-align: right;
  float: right;
}
.flight-card .flight-card-content .flight-from span,
.flight-card .flight-card-content .flight-to span {
  display: block;
}
.flight-card .flight-card-content .flight-from .from-code,
.flight-card .flight-card-content .flight-from .to-code,
.flight-card .flight-card-content .flight-to .from-code,
.flight-card .flight-card-content .flight-to .to-code {
  font-size: 25px;
  color: #002c5f;
  font-weight: 200;
}
.flight-card .flight-card-content .flight-from .from-city,
.flight-card .flight-card-content .flight-from .to-city,
.flight-card .flight-card-content .flight-to .from-city,
.flight-card .flight-card-content .flight-to .to-city {
  font-size: 11px;
  color: #002c5f;
  font-weight: 400;
}
.flight-card .flight-card-content .flight-details-row {
  width: 100%;
  display: grid;
  padding: 5px 15px;
  grid-template-columns: 10% 25% 65%;
}
.flight-card .flight-card-content .flight-details-row span {
  display: block;
}
.flight-card .flight-card-content .flight-details-row .title {
  color: #6a8597;
  font-size: 8px;
  letter-spacing: 3px;
}
.flight-card .flight-card-content .flight-details-row .detail {
  margin-top: 0.2em;
  color: #002c5f;
  font-size: 10px;
}
.flight-card .flight-card-content .flight-details-row .flight-operator {
  text-align: left;
  float: left;
}
.flight-card .flight-card-content .flight-details-row .flight-class {
  float: right;
  text-align: right;
}
.flight-card .flight-card-content .flight-details-row .flight-number {
  padding-left: 80px;
}

.hours-table {
  border-collapse: collapse; /* Ensure borders are merged */
  width: 100%; /* Optional: adjust to your layout needs */
}

.hours-table td {
  border: 1px solid #ccc; /* Border around each cell */
  padding: 8px; /* Optional: padding for better spacing */
}

.hours-table tr {
  border-bottom: 1px solid #ccc; /* Border between rows */
}

.hours-table tr.today {
  background-color: #f2f2f2; /* Optional: highlight today's row */
}

.displayCenter {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
